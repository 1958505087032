import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import "../Styles/PortalPolicies.css";
import { Tooltip } from "antd";

const TermsAndConditionsModal = ({ showModal, handleAgree }) => {
  const [isChecked, setIsChecked] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleAgreeClick = () => {
    if (isChecked) {
      handleAgree();
    }
  };
  return (
    <AnimatePresence>
      {showModal && (
        <>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.5 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="modal-overlay"
          />

          <motion.div
            initial={{ y: "100%", opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: "100%", opacity: 0 }}
            transition={{ duration: 0.5 }}
            className="modal-container"
          >
            <h2 className="modal-title">iNextLabs Portal Usage Policies</h2>
            <p className="modal-paragraph">
              The iNextLabs Partner Portal is a dedicated platform exclusively
              for partners of iNextLabs, designed to support their engagements
              and showcase iNextLabs’ solutions. Partners must adhere to strict
              guidelines regarding access and usage of the portal; sharing
              credentials or providing access outside of their organization
              without explicit permission from iNextLabs is strictly prohibited
              and considered a serious offense. The portal includes demos
              intended for presenting iNextLabs solutions to potential
              customers, and partners are encouraged to use these demos
              judiciously, as they incur usage charges that are tracked by
              partner. To help manage costs, video demonstrations are provided
              as an alternative to live demos.
            </p>
            <p className="modal-paragraph">
              Should partners encounter any issues with the demos, iNextLabs
              offers prompt support to ensure any problems are quickly resolved.
              Partners are encouraged to reach out to the support team if they
              find any demo not functioning correctly, and iNextLabs will
              address the issue immediately. This ensures that partners can
              reliably showcase the full capabilities of iNextLabs' solutions to
              their prospective clients without disruption.
            </p>

            <div className="checkbox-container">
              <input
                type="checkbox"
                id="termsCheckbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
                className="terms-checkbox"
              />
              <label htmlFor="termsCheckbox" className="checkbox-label">
                I understand and agree to the terms and conditions
              </label>
            </div>

            <div className="agree-button-container">
              <Tooltip
                title={
                  !isChecked ? "Please agree to the terms and conditions" : ""
                }
                placement="top"
                overlayClassName="custom-tooltip"
              >
                <button
                  onClick={handleAgreeClick}
                  className="agree-button"
                  disabled={!isChecked}
                  onMouseEnter={() => !isChecked && setShowTooltip(true)}
                  onMouseLeave={() => setShowTooltip(false)}
                >
                  Agree
                </button>
              </Tooltip>
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
};

export default TermsAndConditionsModal;
