import React from "react";
import { Button, Drawer } from "antd";
import { useState } from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import "../Styles/NotesDrawer.css";

const NotesDrawer = ({ markdown, buttonColor }) => {
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        type="primary"
        onClick={showDrawer}
        style={{ border: "none", backgroundColor: buttonColor }}
      >
        <InfoCircleOutlined style={{ fontSize: "20px" }} />
      </Button>
      <Drawer
        title="Note"
        width={700}
        placement="left"
        onClose={onClose}
        open={open}
      >
        <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
          {markdown}
        </ReactMarkdown>
      </Drawer>
    </>
  );
};

export default NotesDrawer;
