import React, { useEffect, useState } from "react";
import "../Styles/Solutions.css";
import { Link } from "react-router-dom";
import { appInsights } from "../AppInsights";
import { BsFillDiagram3Fill } from "react-icons/bs";
import { MdPlayCircleOutline } from "react-icons/md";
import { motion, AnimatePresence } from "framer-motion";
import { IoClose } from "react-icons/io5";

const Solutions = ({ content, heightsize }) => {
  const headerContent = content[0];

  const [userData, setUserData] = useState(null);
  const [modal, setModal] = useState({ type: null, data: null });

  useEffect(() => {
    const sessionKey = sessionStorage.getItem("msal.account.keys");
    if (sessionKey) {
      const data = JSON.parse(sessionKey);
      const sessionData = data[0];
      const userDataString = sessionStorage.getItem(sessionData);
      if (userDataString) {
        const parsedData = JSON.parse(userDataString).idTokenClaims;
        setUserData(parsedData);
      }
    }
  }, []);

  const trackLinkClick = (title) => {
    if (userData) {
      appInsights.trackEvent({
        name: `${title} opened`,
        properties: {
          title,
          email: userData.emails[0],
          user: userData.name,
        },
      });
    }
  };

  const openModal = (type, data) => {
    setModal({ type, data });
  };

  const closeModal = () => {
    setModal({ type: null, data: null });
  };

  return (
    <>
      <div className="partner-demo-solution-outer-container">
        <div className="partner-demo-solution-inner-container">
          {headerContent.type === "enterprise" && (
            <div className="partner-demo-solution-left-container">
              <img src={headerContent.headerImage} alt="Header" />
            </div>
          )}

          <div className="partner-demo-solution-right-container">
            <div className="partner-demo-solution-heading flex flex-col">
              <div className="w-full flex justify-between">
                <div>
                  {headerContent.icon}
                  <h4>{headerContent.title}</h4>
                </div>
              </div>
              <p>{headerContent.description}</p>
            </div>

            {headerContent.type === "enterprise" && (
              <div className="partner-demo-solution-left-asset">
                <button
                  onClick={() =>
                    openModal("diagram", headerContent.architectureImage)
                  }
                >
                  <BsFillDiagram3Fill size={25} />
                  Architecture Diagram
                </button>
                {headerContent.demoVideo ? (
                  <button
                    onClick={() => openModal("video", headerContent.demoVideo)}
                    className="video-button"
                  >
                    <MdPlayCircleOutline size={25} />
                    Video
                  </button>
                ) : (
                  <button className="video-button" disabled>
                    <MdPlayCircleOutline size={25} />
                    Video
                    <span className="coming-soon">Coming Soon</span>
                  </button>
                )}
              </div>
            )}

            <div className="partner-demo-solution-content">
              {content[1].map((content, index) => (
                <React.Fragment key={index}>
                  {content.status !== "Coming Soon" ? (
                    <Link
                      to={
                        content.urlType === "router"
                          ? content.url
                          : content.urlType === "link/internal"
                          ? `/launch-demo/${content.title
                              .toLowerCase()
                              .replace(/ /g, "-")}`
                          : content.url
                      }
                      target={
                        content.urlType === "link/external" ? "_blank" : ""
                      }
                      className="partner-demo-solution-content-card-link"
                      onClick={() => trackLinkClick(content.title)}
                    >
                      <div
                        className="partner-demo-solution-content-card"
                        style={{ height: heightsize }}
                      >
                        <div className="partner-demo-solution-content-card-header">
                          <div className="partner-demo-solution-content-card-image">
                            <img
                              src={content.image}
                              alt={content.title}
                              width={50}
                            />
                          </div>
                        </div>
                        <div className="partner-demo-solution-content-card-text flex flex-col gap-1">
                          <h3>{content.title}</h3>
                        </div>
                      </div>
                    </Link>
                  ) : (
                    <div
                      className="partner-demo-solution-content-card"
                      style={{ height: heightsize }}
                    >
                      <div className="partner-demo-solution-content-card-header">
                        <div className="partner-demo-solution-content-card-image">
                          <img
                            src={content.image}
                            alt={content.title}
                            width={50}
                          />
                        </div>
                        {content.status && (
                          <div className="partner-demo-solution-content-card-status">
                            <span>{content.status}</span>
                          </div>
                        )}
                      </div>
                      <div className="partner-demo-solution-content-card-text flex flex-col gap-1">
                        <h3>{content.title}</h3>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Modal for Diagrams and Videos */}
      <AnimatePresence>
        {modal.type && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="solution-partner-modal-overlay"
            onClick={closeModal}
          >
            <motion.div
              initial={{ scale: 0.8 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.8 }}
              onClick={(e) => e.stopPropagation()}
              className="solution-partner-modal"
            >
              {modal.type === "diagram" && modal.data && (
                <>
                  <div className="solution-partner-modal-header">
                    <h2 className="solution-partner-modal-title">
                      Architecture Diagram
                    </h2>
                    <button onClick={closeModal} className="top-4 right-4">
                      <IoClose size={25} />
                    </button>
                  </div>
                  <img
                    src={modal.data}
                    alt="Architecture Diagram"
                    className="solution-partner-modal-img"
                  />
                </>
              )}
              {modal.type === "video" && modal.data && (
                <>
                  <div className="solution-partner-modal-header">
                    <h2 className="solution-partner-modal-title">Demo Video</h2>
                    <button onClick={closeModal} className="top-4 right-2">
                      <IoClose size={25} />
                    </button>
                  </div>
                  <video
                    autoPlay
                    muted
                    controls
                    loop
                    className="solution-partner-modal-video"
                  >
                    <source src={modal.data} />
                  </video>
                </>
              )}
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default Solutions;
