import { HomeOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";

const LogInsights = () => {
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          margin: "30px",
        }}
      >
        <a href="/">
          <Button
            type="primary"
            className="home-redirect-button"
            style={{ backgroundColor: "#f05742" }}
          >
            <HomeOutlined style={{ fontSize: "20px" }} />
          </Button>
        </a>
      </div>
      <iframe
        src="https://loganalyzer-f6ckbad8gff0atcp.southeastasia-01.azurewebsites.net/"
        className="w-full h-[90vh] border-none"
        title="HTML File"
      ></iframe>
    </div>
  );
};

export default LogInsights;
