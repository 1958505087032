import React from "react";
import { Helmet } from "react-helmet";
import NotesDrawer from "../NotesDrawer";
import { markdownContent } from "../content/markdownContent";
import { HomeOutlined } from "@ant-design/icons";
import { Button } from "antd";

const AvatarVoice = () => {
  const markdown = markdownContent.avatarVoice;
  return (
    <div>
      <div class="avatar-voice-header-outer">
        <div class="avatar-voice-header-inner">
          <div class="avatar-voice-header-title">
            <h1>Talking Avatar Chat Demo</h1>
          </div>
          <div
            class="avatar-voice-header-nav-btn"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "1rem",
              gap: "10px",
            }}
          >
            <a href="/">
              <Button>
                <HomeOutlined style={{ fontSize: "20px" }} />
              </Button>
            </a>
            <NotesDrawer markdown={markdown} buttonColor={"#f05742"} />
          </div>
        </div>
      </div>
      <Helmet>
        <script src="./webchat/avatar-voice.js"></script>
      </Helmet>
    </div>
  );
};

export default AvatarVoice;
