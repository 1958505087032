import "./App.css";
import React, { useEffect, useState } from "react";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "./authConfig";
import AppRouter from "./Routes/AppRouter";
import Header from "./Components/Header";
import Banner from "./Components/Banner";
import Solutions from "./Components/Solutions";
import { ChannelContent } from "./Components/content/ChannelContent";
import Footer from "./Components/Footer";
import PortalPoliciesModal from "./Components/PortalPolicies";

export const AppContent = () => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const hasAgreed = localStorage.getItem("portalPolicies");
    if (!hasAgreed) {
      setShowModal(true);
    }
  }, []);

  const handleAgree = () => {
    localStorage.setItem("portalPolicies", "true");
    setShowModal(false);
  };

  return (
    <>
      <div className="inextlabs-demo-web-container">
        <Header />
        <Banner />
        <Solutions content={ChannelContent.assistants} />
        <Solutions content={ChannelContent.analyzers} />
        <Solutions content={ChannelContent.insights} />
        <Solutions content={ChannelContent.saas} />
        <Solutions content={ChannelContent.channel} />
        <Footer />
        <PortalPoliciesModal showModal={showModal} handleAgree={handleAgree} />
      </div>
    </>
  );
};

export const AppContentContainer = () => {
  return (
    <>
      <AppRouter />
    </>
  );
};

const App = () => {
  const authRequest = {
    ...loginRequest,
  };

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
    >
      <AppContentContainer />
    </MsalAuthenticationTemplate>
  );
};

export default App;
