import { HomeOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { Helmet } from "react-helmet";
import { markdownContent } from "../content/markdownContent";
import NotesDrawer from "../NotesDrawer";

const ElectoBot = () => {
  const markdown = markdownContent.electo;
  return (
    <div>
      <div
        className="electo-bot-header"
        style={{
          display: "flex",
          justifyContent: "flex-end",
          margin: "30px",
          gap: "10px",
        }}
      >
        <a href="/">
          <Button
            type="primary"
            className="home-redirect-button"
            style={{ backgroundColor: "#a03448" }}
          >
            <HomeOutlined style={{ fontSize: "20px" }} />
          </Button>
        </a>
        <NotesDrawer markdown={markdown} buttonColor={"#a03448"} />
      </div>
      <Helmet>
        <script
          src="https://demobotscommonstorage.blob.core.windows.net/inext-store-web-bot/webchat-electo.js"
          type="text/javascript"
        />
      </Helmet>
    </div>
  );
};

export default ElectoBot;
