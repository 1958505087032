import { HomeOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import NotesDrawer from "../NotesDrawer";
import { markdownContent } from "../content/markdownContent";

const CustomerSupportBot = () => {
  const location = useLocation();
  const markdown = markdownContent.customerSupport;

  useEffect(() => {
    const pathname = location.pathname;
    const pathAfterSlash = pathname.substring(pathname.indexOf("/") + 1);

    if (pathAfterSlash !== localStorage.getItem("botName")) {
      if (localStorage.getItem("token")) {
        localStorage.removeItem("userData");
        localStorage.removeItem("conversationId");
        localStorage.removeItem("token");
        localStorage.removeItem("watermark");
      }
    }
  }, [location]);
  return (
    <div className="customer-bot-container">
      <div
        className="customer-bot-header"
        style={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "1rem",
          gap: "10px",
        }}
      >
        <a href="/">
          <Button
            type="primary"
            className="home-redirect-button"
            style={{ backgroundColor: "#f05742" }}
          >
            <HomeOutlined style={{ fontSize: "20px" }} />
          </Button>
        </a>
        <NotesDrawer markdown={markdown} buttonColor={"#f05742"} />
      </div>
      <div id="root"></div>
      <Helmet>
        <script
          src="https://demobotscommonstorage.blob.core.windows.net/demowebchats/bundle.js"
          type="text/javascript"
        />
      </Helmet>
    </div>
  );
};

export default CustomerSupportBot;
